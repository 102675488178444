<template>
    <a-date-picker
        v-model="date"
        style="width: 100%;"
        :locale="locale"
        :dropdownClassName="dropdownClass"
        :placeholder="$t(placeholder)"
        :size="size"
        :format="dateFormat"
        :show-time="showTimeOptions"
        :getCalendarContainer="getCalendarContainer"
        :allowClear="allowClear"
        :valueFormat="valueFormat"
        :disabled-date="checkDisabledDate"
        :disabled-time="checkDisabledTime"
        :autoFocus="autoFocus"
        @openChange="dateOpenChange"
        @change="dateChange" />
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import IMask from 'imask'
export default {
    name: "appComponentsDatepicker",
    props: {
        value: {
            type: [String, Date, Object]
        },
        getCalendarContainer: {
            type: [String, Function],
            default: null
        },
        placeholder: {
            type: [String],
            default: "select_date"
        },
        startTime: {
            type: Boolean,
            default: true
        },
        mask: {
            type: [String],
            default: 'DD.MM.YYYY HH:mm'
        },
        dateFormat: {
            type: String,
            default: 'DD.MM.YYYY HH:mm'
        },
        showTime: {
            type: [Boolean, Object],
            default: false
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        autoFocus: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'default'
        },
        dropdownClassName: {
            type: String,
        },
        dateLimit: {
            type: [String, Object],
            default: null
        },
        dateLimitFrom: {
            type: [String, Object],
            default: null
        },
        valueFormat: {
            type: [Object, String],
            default: null
        },
        rangeLimit: {
            type: [String, Object],
            default: null
        },
        rangeLimitDeadLine: {
            type: [String, Object],
            default: null
        },
        planLimit: {
            type: [String, Object],
            default: null
        }
    },
    computed: {
        date:{
            get(){
                return this.value
            },
            set(val){
                if(val)
                    this.$emit('input', val)
                else
                    this.$emit('input', null)
            }
        },
        dropdownClass(){
            return this.dropdownClassName ? this.dropdownClassName : `popup_date_${this.dropRandom}`
        },
        showTimeOptions() {
            const options = this.showTime
            const defaultTime = this.startTime ? '09:00:00' : '18:00:00'
            options.defaultValue = this.$moment(defaultTime, 'HH:mm:ss')

            return options
        }
    },
    data(){
        return{
            locale,
            dropRandom: Math.floor(Math.random(100,100))
         
        }
    },
    methods: {
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        disabledDateTime() {
            return {
                disabledHours: () => this.range(0, this.$moment(this.dateLimit).add(1, 'hours').format('HH'))
            }
        },
        disabledDateTimeFrom() {
            return {
                disabledHours: () => this.range(this.$moment(this.dateLimit).subtract({hours:1}).format('HH'), 24)
            }
        },
        checkDisabledTime() {
            if(this.dateLimitFrom){
                return this.disabledDateTimeFrom()
            } else if(this.dateLimit){
                return this.disabledDateTime()
            } else {
                return false
            }
        },
        disabledDate(current) {
            return current && current < this.$moment(this.dateLimit).subtract(1, 'days').endOf('day')
        },
        disabledDateFrom(current) {
            return current && current > this.$moment(this.dateLimitFrom).endOf('day')
        },
        disabledDateRange(current) {
            const endDate = typeof this.dateLimitFrom ? this.dateLimitFrom : this.dateLimitFrom.format()
            if(this.$moment(this.rangeLimit).isSame(current.format(), 'day')) {
                return false
            } else
                return !this.$moment(current).isBetween(this.rangeLimit, endDate)
        },
        disabledDateRangeDeadLine(current) {
            if(this.$moment(this.rangeLimitDeadLine).isSame(current.format(), 'day') || this.$moment(this.planLimit).isSame(current.format(), 'day')) {
                return false
            } else 
                return !this.$moment(current).isBetween(this.planLimit, this.rangeLimitDeadLine)
        },
        checkDisabledDate(current) {
            if(this.rangeLimit && this.dateLimitFrom) {
                return this.disabledDateRange(current)
            } else if(this.rangeLimitDeadLine && this.planLimit) {
                return this.disabledDateRangeDeadLine(current)
            } else {
                if(this.dateLimitFrom) {
                    return this.disabledDateFrom(current)
                } else if(this.dateLimit) {
                    return this.disabledDate(current)
                } else {
                    return false
                }
            }
        },
        dateOpenChange(status) {
            this.$nextTick(() => {
                let mask = null
                
                if(status) {
                    this.$nextTick(() => {
                        const input = document.querySelector(`.${this.dropdownClass} .ant-calendar-input `)
                        if(input) {
                            mask = IMask(input, {
                                mask: Date,
                                pattern: this.dateFormat,
                                format: (date) => {
                                    return this.$moment(date).format(this.dateFormat)
                                },
                                parse: (str) => {
                                    return this.$moment(str, this.dateFormat)
                                },
                                blocks: {
                                    YYYY: {
                                        mask: IMask.MaskedRange,
                                        from: 1970,
                                        to: 2090
                                    },
                                    MM: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 12
                                    },
                                    DD: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 31
                                    },
                                    HH: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 23
                                    },
                                    mm: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 59
                                    }
                                }
                            })
                        }
                    })
                } else
                    mask = null
            })
        },
        dateChange(val){
            this.$emit('input', val)
            this.$emit('change', val)
        }
    }
   
}
</script>